<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center" class="mb-2">
        <v-col cols="auto" class="pr-12">
          <h1>Discount Codes</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Discount Codes"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  v-on="on"
                  class="mr-2"
                  @click="$refs.discountCodeDialog.open()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Create New Discount Code</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="discountCodeTableHeaders"
        :items="discountCodes"
        class="mt-4"
        no-data-text="No discount codes found"
      >
        <template v-slot:item.discount="{ item }">
          <span v-if="item.discount_type === 'number'"
            >£{{ item.discount_amount }}</span
          >
          <span v-else>{{ item.discount_amount }}%</span>
        </template>
        <template v-slot:item.valid_to="{ item }">
          {{ formatDate(item.valid_to.substring(0, 10)) }}
        </template>
        <template v-slot:item.is_limited="{ item }">
          <v-chip label small color="success" v-if="item.is_limited"
            >Yes</v-chip
          >
          <v-chip label small color="error" v-else>No</v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="blue lighten-4 blue--text"
                v-on="on"
                class="mr-2"
                @click="$refs.discountCodeDialog.open(item)"
              >
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="red lighten-4 red--text"
                v-on="on"
                @click="openDelete(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Archive</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog v-model="deleteDiscountCode.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Archive Discount Code</v-card-title>
        <v-card-text
          >Are you sure you wish to archive
          {{ deleteDiscountCode.discountCode.code }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteDiscountCode.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <discount-code-dialog ref="discountCodeDialog" />
  </div>
</template>

<script>
import DiscountCodeDialog from "./components/DiscountCodeDialog";

export default {
  components: {
    DiscountCodeDialog,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Discount Codes",
          disabled: true,
        },
      ],
      searchTerm: "",
      discountCodeTableHeaders: [
        { text: "Code", value: "code" },
        { text: "Discount", value: "discount" },
        { text: "Valid From", value: "formatted_dates.begins_at" },
        { text: "Valid Until", value: "formatted_dates.expires_at" },
        { text: "Is Limited?", value: "is_limited" },
        { text: "Limited Amount", value: "limited_to" },
        { text: "Actions", value: "actions", align: "end" },
      ],
      deleteDiscountCode: {
        dialog: false,
        discountCode: {},
        loading: false,
      },
    };
  },

  methods: {
    openDelete(discountCode) {
      this.deleteDiscountCode.discountCode = discountCode;
      this.deleteDiscountCode.dialog = true;
    },

    resetDelete() {
      this.deleteDiscountCode.dialog = false;
      this.deleteDiscountCode.discountCode = {};
      this.deleteDiscountCode.loading = false;
    },

    saveDelete() {
      this.deleteDiscountCode.loading = true;

      this.$store
        .dispatch("arnprior/vouchersStore/deleteDiscountCode", {
          appId: this.$route.params.id,
          discountCodeId: this.deleteDiscountCode.discountCode.id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteCoupon.loading = false;
        });
    },
  },

  computed: {
    discountCodes() {
      let discountCodes =
        this.$store.getters["arnprior/vouchersStore/discountCodes"];

      if (this.searchTerm !== "") {
        discountCodes = discountCodes.filter((c) => {
          const code = c.code.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();

          return code.includes(searchTerm);
        });
      }

      return discountCodes;
    },
  },
};
</script>
